<template>
<div class="order contW">
  <div class="order-top">
    <myBreadcrumb></myBreadcrumb>
  </div>
  <div class="order-tit">确认订单</div>
  <div class="order-info">
    <img :src="info.img" width="220" height="150" />
    <div class="order-info-r">
      <div class="order-info-r-tit">{{ info.name }}</div>
      <div class="order-info-r-desc">{{ info.desc }}</div>
      <div class="order-info-r-val">
        <div>共 {{ info.map && info.map.node_last_count }} 节课</div>
        <div class="order-info-r-val-money" v-if="info.price && payWay == 1">{{ info.price == 0  ? '限时免费' : '￥'+ info.price }}</div>
      </div>
    </div>
  </div>
  <div class="order-rule">
<!--    <div class="order-rule-item">-->
<!--      <div class="order-rule-item-tit">-->
<!--        考试有效期：<span>{{info.exam_indate}}个月</span>-->
<!--      </div>-->
<!--      <div>注：请在支付成功后{{info.exam_indate}}个月内完成技能培训和认证考试。</div>-->
<!--    </div>-->
    <div class="order-rule-item">
      <div class="order-rule-item-tit">
        考试次数：<span>{{info.attr && info.attr.allow_exam_num}}次</span>
      </div>
      <div>注：用户在考试有效期内有{{info.attr && info.attr.allow_exam_num}}次考试机会，考试通过后可获得认证证书。</div>
    </div>
    <div class="order-rule-item">
      <div class="order-rule-item-tit">
        证书有效期：<span>{{info.cert_indate}}年</span>
      </div>
      <div>注：获得证书之日起{{info.cert_indate}}年有效期，过期需重新考试获得认证证书。</div>
    </div>
  </div>
  <div class="uncertification" v-if="verifyRealname == 0">
    <div class="uncertification-tip">
      注：由于知识产品的特性，购买后将无法进行退换、转让，请您确认是否继续购买。请您理解，根据相关规定，本认证服务仅面向社会人士，不向未成年人提供，故本服务须实名认证。
      购买完成后，仅限该账号实名认证后的自然人使用。
    </div>
    <div class="uncertification-info">
      <div class="uncertification-info-item">
        <span>真实姓名：</span>
        <div>
          <el-input class="login-cont-center-r-input-nr" v-model="name" placeholder="请输入真实姓名"></el-input>
        </div>
      </div>
      <div class="uncertification-info-item">
        <span>身份证号：</span>
        <div>
          <el-input class="login-cont-center-r-input-nr" v-model="idCard" placeholder="请输入身份证号"></el-input>
        </div>
      </div>
      <div class="uncertification-info-btn" @click="submitRealname">提交认证</div>
    </div>
  </div>
  <div class="certification" v-else>
    <div class="certification-info">
      <span class="certification-info-tag">已实名</span>
      <span>真实姓名：{{ realName }}</span>
      <span>身份证号：{{ idCardNum }}</span>
    </div>
    <div class="certification-tip">注：由于知识产品的特性，购买后将无法进行退换、转让，请您确认是否继续购买。请您理解，根据相关规定，本认证服务仅面向社会人士，不向未成年人提供，故本服务须实名认证。购买完成后，仅限该账号实名认证后的自然人使用。</div>
  </div>

  <div class="pay-type">
    <div class="order-sub-tit">请选择支付方式：</div>
    <div class="pay-type-cont">
      <div class="pay-type-cont-item" :class="payWay == '1' ? 'active' : ''" @click="payWay = '1'" v-if="$store.state.payType == '1' || $store.state.payType == '2'">
        <img v-if="payWay != '1'" src="../../assets/images/common/wxz.png" width="20" height="20" />
        <img v-else src="../../assets/images/common/xz_radio.png" width="20" height="20" />
        <img class="pay-type-cont-item-img" src="../../assets/images/attestation/zfb.png" height="31" width="31" />
        <p>支付宝支付</p>
      </div>
      <div class="pay-type-cont-item" :class="payWay == '0' ? 'active' : ''" @click="payWay = '0'" v-if="$store.state.payType == '0' || $store.state.payType == '2'">
        <img v-if="payWay != '0'" src="../../assets/images/common/wxz.png" width="20" height="20" />
        <img v-else src="../../assets/images/common/xz_radio.png" width="20" height="20" />
        <img class="pay-type-cont-item-img" src="../../assets/images/attestation/jhm.png" height="31" width="31" />
        <p>激活码</p>
      </div>
    </div>
  </div>
  <div class="order-cdKey" v-if="payWay == '0'">
    <p class="order-cdKey-tit">请输入激活码：</p>
    <el-input class="cdKey-active-input" v-model="code"  placeholder="请输入激活兑换码" />
  </div>
  <div class="confirm-pay">
    <div class="confirm-pay-money" v-if="payWay =='1'">需付金额：<span>{{ info.price == 0  ? '限时免费' : '￥'+ info.price }}</span></div>
    <div class="confirm-pay-agreement" @click="changeAgree">
      <img v-if="agreeStatus" src="../../assets/images/common/xz.png" width="16" height="16" />
      <img v-else src="../../assets/images/common/wx.png" width="16" height="16" />
      <p>同意<span @click.stop="goAgree"  target="_blank">《认证服务协议》</span>，退款条件：电子商品一经售出不予退款。</p>
    </div>
    <div class="confirm-pay-btn" @click="activeCode" v-if="payWay == '0'">立即激活</div>
    <div class="confirm-pay-btn" @click="pay" v-else>立即激活</div>
  </div>
  <el-dialog
      :visible.sync="activeDialog"
      width="340px"
      :show-close="false"
      @close="closeDialog"
  >
    <div slot="title"></div>

    <div class="confirm-txt">激活成功！</div>
    <div class="confirm-txt-sub">已激活《{{ categoryInfo.name }}》认证</div>
    <div class="confirm-txt-sub">可在个人中心-我的学习中查看学习</div>
    <div class="confirm-txt-sub">有效期至：{{util.timeFormatter(
        new Date((+certToUser.exam_end_time) * 1000),
        'yyyy.MM.dd hh:mm') }}</div>
    <div slot="footer" class="confirm-submit">
      <template>
        <div class="confirm-submit-btn" @click="goInfo(categoryInfo.id)">去看看</div>
      </template>
    </div>
  </el-dialog>
  <el-dialog
      :title="payWay == 1 ? '支付宝支付' : '微信支付'"
      :visible.sync="payModal"
      width="352px"
      @close="closePayModal"
  >
<!--    <div slot="title">-->
<!--      <img src="../../assets/images/attestation/order_top.png" width="352" height="200" />-->
<!--    </div>-->
    <div class="ali-code">
      <div>
        <img :src="payUrl" width="178" height="178" />
      </div>
      <p class="ali-code-txt">扫描二维码进行支付</p>
    </div>
  </el-dialog>
</div>
</template>

<script>
import util from '@/utils/index.js'
export default {
  name: "order",
  data(){
    return {
      util:util,
      certId:'',
      name:'',
      idCard:'',
      info:{},
      verifyRealname:0,
      realName:'',
      idCardNum:'',
      payWay:'0',
      agreeStatus:true,
      payModal:false,
      payUrl:'',
      timer:null,
      sec:0,
      code:'',
      activeDialog:false,
      categoryInfo:{},
      certToUser:{},
    }
  },
  watch:{
    sec(newV){
      console.log(newV,'newVnewVnewV')
      if(newV >= 120){
        clearInterval(this.timer);
      }
    },
    '$store.state.payType'(newV){
      if(newV == 1){
        this.payWay = '1';
      }
    }
  },
  created(){
    this.certId = this.$route.query.id || '';
    if(this.$store.state.payType == 1){
      this.payWay = '1';
    }
    this.getDetail();
    this.isVerifyRealname();
  },
  beforeDestroy(){
    clearInterval(this.timer);
  },
  methods:{
    getDetail(){
      let params = {
        id:this.certId,
        with_progress:1
      };
      this.api.attestation.certDetail(params).then((res)=>{
        this.info = res.info;
      })
    },
    isVerifyRealname(){
      this.api.user.isVerifyRealname().then((res)=>{
        this.verifyRealname = res.is_verify_realname;
        this.realName = res.realname;
        this.idCardNum = res.id_card_num;
      })
    },
    submitRealname(){
      if(!this.name){
        this.$message({
          message: '请输入真实姓名',
          type: 'warning'
        });
        return;
      }
      if(!this.idCard){
        this.$message({
          message: '请输入身份证号',
          type: 'warning'
        });
        return;
      }
      let params = {
        real_name:this.name,
        id_card_num:this.idCard
      }
      this.api.user.verifyRealname(params).then((res)=>{
        this.realName = res.info.realname;
        this.idCardNum = res.info.id_card_num;
        this.verifyRealname = '1';
      })
    },
    changeAgree(){
      this.agreeStatus = !this.agreeStatus;
    },
    activeCode(){
      if(this.verifyRealname == 0){
        this.$message({
          message: '请进行实名验证',
          type: 'warning'
        });
        return;
      }
      if(!this.code){
        this.$message({
          message: '请输入激活码',
          type: 'warning'
        });
        return;
      }

      if(!this.agreeStatus){
        this.$message({
          message: '请同意《技能认证服务协议》',
          type: 'warning'
        });
        return;
      }
      let params = {
        code:this.code,
        is_sure:1,
        category_id:this.certId,
      };
      this.api.user.cdkeyActive(params).then((res)=>{
        this.activeDialog = true;
        this.categoryInfo = res.info;
        this.certToUser = res.cert_to_user;
      })
    },
    goInfo(id){
      this.$router.push({
        path:'/attestation/detail',
        query:{
          id:id
        }
      })
      this.activeDialog = false;
      this.code = '';
    },
    closeDialog(){
      this.goInfo(this.categoryInfo.id)
    },
    pay(){
      if(this.verifyRealname == 0){
        this.$message({
          message: '请进行实名验证',
          type: 'warning'
        });
        return;
      }
      if(!this.agreeStatus){
        this.$message({
          message: '请同意《技能认证服务协议》',
          type: 'warning'
        });
        return;
      }
      this.sec = 0;
      let params = {
        op:'create',
        cert_id:this.certId,
        payment_type:this.payWay
      };
      this.api.attestation.orderCreate(params).then((res)=>{
        if(res.qr_code){
          //去支付
          this.payModal = true;
          this.payUrl = res.qr_code;
          //等待是否支付完成
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            this.sec++;
            let data = {
              id: res.order.id,
            };
            this.api.attestation.getPayStatus(data).then((res) => {
              if(res.info.status == 1){
                clearInterval(this.timer);
                // this.$notice({
                //   type: "success",
                //   message: "支付成功",
                // }).isShow();
                this.$message({
                  message: '支付成功',
                  type: "success",
                });
                this.payModal = false;
                //支付完成  进入练习页面
                this.$router.push({
                  path:'/attestation/detail',
                  query:{
                    id:this.certId
                  }
                })
              }

            });
          }, 5000);
        }else{
          //进入练习页面
          this.$router.push({
            path:'/attestation/detail',
            query:{
              id:this.certId
            }
          })
        }


      })
    },
    closePayModal(){
      // clearInterval(this.timer);
    },
    goAgree(){
      window.open('https://shizhanyun.feishu.cn/wiki/Cx7GwNpjKicBPakoftHcpKfKnkg?from=from_copylink','_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.order{
  margin-top: 84px;
  .order-top{
    color: #666666;
  }
  .order-tit{
    margin-top: 36px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    color: $theme-blue;
  }
  .order-info{
    padding: 0 30px;
    height: 200px;
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0,35,136,0.08);
    border-radius: 10px;
    @include flex(row,flex-start,center);
    >img{
      margin-right: 30px;
      width: 220px;
    }
    .order-info-r{
      flex: 1;
      width: 0;
      .order-info-r-tit{
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: bold;
      }
      .order-info-r-desc{
        margin-bottom: 10px;
        color: #666666;
        line-height: 25px;
        height: 50px;
        display: -webkit-box; /* 必须设置display属性为-webkit-box */
        overflow: hidden; /* 超出部分隐藏 */
        text-overflow: ellipsis; /* 显示省略号 */
        -webkit-line-clamp: 2; /* 限制显示两行 */
        -webkit-box-orient: vertical; /* 垂直方向上的换行 */
      }
      .order-info-r-val{
        @include flex(row,space-between,center);
        .order-info-r-val-money{
          font-size: 24px;
          font-weight: bold;
          color: $theme-blue;
        }
      }
    }
  }
  .order-rule{
    margin: 30px 0;
    @include flex(row,flex-start,center);

    .order-rule-item{
      padding: 30px 60px;
      flex: 1;
      width: 0;
      margin-right: 44px;
      height: 90px;
      background: #FAFBFC;
      border-radius: 10px;
      line-height: 24px;
      border: 1px dashed #8EABFF;

      .order-rule-item-tit{
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        >span{
          color:$theme-blue;
        }
      }
    }
    .order-rule-item:last-child{
      margin-right: 0;
    }
  }
  .order-sub-tit{
    font-size: 16px;
    font-weight: bold;
  }
  .certification{

    .certification-info{
      font-size: 14px;
      margin: 16px 0;
      >span{
        margin-right: 40px;
      }
      .certification-info-tag{
        margin-right: 20px;
        display: inline-block;
        @include btn(50px,20px,4px, 14px,$theme-green,#FFFFFF);
      }
    }
    .certification-tip{
      margin-bottom: 30px;
      font-size: 14px;
      width: 1136px;
      color: #999999;
      line-height: 24px;
    }
  }
  .uncertification{
    margin-bottom: 30px;
    padding: 45px 60px;
    background: #FFF7F7;
    border: 1px dashed #FFB3B3;
    border-radius: 10px;
    .uncertification-tip{
      margin-bottom: 30px;
      font-size: 14px;
    }
    .uncertification-info{
      @include flex(row,flex-start,center);
      .uncertification-info-item{
        font-size: 16px;
        margin-right: 30px;
        @include flex(row,flex-start,center);
        >div{
          padding: 0 30px;
          width: 280px;
          height: 50px;
          background: #FFFFFF;
          border: 1px solid #DCDCDC;
          border-radius: 25px;
        }
      }
    }
    .uncertification-info-btn{
      @include btn(140px,50px,25px,14px,#C00000);
      font-weight: bold;
    }
  }
  .pay-type{
    .pay-type-cont{
      margin-bottom: 30px;
      margin-top: 20px;
      @include flex(row,flex-start,center);

      .pay-type-cont-item{
        margin-right: 30px;
        width: 250px;
        height: 68px;
        background: #FAFBFC;
        border-radius: 10px;
        border: 2px solid transparent;
        cursor: pointer;
        @include flex(row,center,center);
        .pay-type-cont-item-img{
          margin: 0 6px 0 30px;
        }
      }
      .pay-type-cont-item.active{
        border: 2px solid #3669FD;
      }
    }
  }
  .order-cdKey{
    margin-bottom: 40px;
    .order-cdKey-tit{
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .confirm-pay{
    border-top: 1px solid $theme-border-gray;
    padding-top: 34px;
    @include flex(column,flex-end,flex-end);
    .confirm-pay-money{
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
      >span{
        font-size: 24px;
        color:$theme-blue;
      }
    }
    .confirm-pay-agreement{
      @include flex(row,flex-start,center);
      cursor: pointer;
      >p{
        margin-left: 10px;
        >span{
          color:$theme-blue;
        }
      }

    }
    .confirm-pay-btn{
      margin-top: 28px;
      margin-bottom: 70px;
      @include btn(170px,48px,24px,16px,linear-gradient(222deg, #3A6CFF, #004FEB));
      font-weight: bold;
    }

  }
  .ali-code{
    padding-top: 156px;
    background: url("../../assets/images/attestation/order_top.png") no-repeat;
    background-size: 352px 200px;
    >div{
      margin: 0 auto;
      width: 204px;
      height: 204px;
      background: #FFFFFF;
      border: 1px solid #E8F0FF;
      border-radius: 10px;
      @include flex(column,center,center);
    }
    .ali-code-txt{
      margin-top: 17px;
      font-size: 16px;
      text-align: center;

    }
  }
  .confirm-txt{
    padding-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .confirm-txt-sub{
    margin: 0 36px;
    line-height: 22px;
  }
  .confirm-submit{
    margin-top: -20px;
    .confirm-submit-btn{
      margin: 0 auto;
      @include btn(110px,38px,19px,14px,linear-gradient(222deg, #3A6CFF, #004FEB));
    }
  }
}

</style>
<style>
.uncertification-info-item .el-input__inner{
  border: none;
  padding: 0;
  height: 50px;
}
.order .el-dialog__header{
  padding: 0;
  height: 0;
  overflow: hidden;
}
.order .el-dialog__body{
  padding: 0 0 30px 0;
}
.cdKey-active-input .el-input__inner{
  /*border: none;*/
  width: 340px;
  height: 50px;
  border-radius: 25px;
}
</style>
